import React from 'react';

export default function SideNav(props) {
  var elementList = null;

  if (props.content) {
    let nodes = props.content.querySelectorAll('h1, h2');

    elementList = [];

    nodes.forEach((e, i) => {
      elementList.push(<li key={i} className={`cursor-pointer pb-1 text-sm
      ${e.innerHTML === props.active ? 'font-bold text-gray-800' : 'text-gray-500'} hover:text-gray-900 ${e.nodeName === 'H3' ? 'pl-4' : ''}`} 
        onClick={e => props.setJumpedHeading(e.currentTarget.innerHTML)}>{e.innerHTML}</li>);
    })
  }

  return (
    <div className=''> 
      <ul>
        {elementList}
      </ul>
    </div>
  )
}
